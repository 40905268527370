.d-flex {
  display: flex !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.justify-content-center {
  justify-content: center !important;
}
.justify-content-end {
  justify-content: flex-end !important;
}
.align-items-center {
  align-items: center !important;
}
.flex-direction-column {
  flex-direction: column !important;
}
.shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}
.border {
  border: 1px solid #c1c1c1 !important;
}

.active-card {
  outline: 4px solid #ffdb16 !important;
}
.RSPBprogressBar {
  height: 4px !important;
}
.text-bolder {
  font-weight: bold !important;
}

.p-1 {
  padding: 1em;
}
.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 2em;
}
.p-2 {
  padding: 2em;
}
.p-0 {
  padding: 0px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.col-gap {
  gap: 10px;
}
.cusor-pointer {
  cursor: pointer;
}
.text-danger {
  color: #c00015;
}
.mt-1 {
  margin-top: 1em
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}

.visiblity-hidden {
  visibility: hidden;
  height: 0px;
  width: 0px;
}